import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { getAccessToken, getTokenExpiration } from '@/services/CookieService'
import AuthService from '@/services/AuthService'

export default async function auth(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const authToken = getAccessToken()

  if (!authToken) {
    next('/login')
    return
  }

  const expiration = getTokenExpiration()
  const now = new Date()

  if (expiration && expiration > now) {
    await AuthService.refresh()
  }

  next()
  return
}
