import type { AuthResponse, LoginForm } from '@/interfaces'
import { useFetchConfig } from '@/composables/useFetch'
import { setAccessToken, removeAccessToken } from '@/services/CookieService' // Importa la función para guardar el token en la cookie

export default {
  async login(payload: LoginForm): Promise<AuthResponse> {
    const { useFetch } = useFetchConfig()

    const response = await useFetch('POST', 'auth/login', payload)

    if (response.status === 401) {
      throw new Error('login')
    }
    if (!response.ok) {
      throw new Error()
    }

    const data: AuthResponse = await response.json()
    setAccessToken(data.access_token)

    return data
  },

  async logout(): Promise<void> {
    const { useFetch } = useFetchConfig()

    const response = await useFetch('POST', 'auth/logout')

    if (!response.ok) {
      throw new Error()
    }

    await removeAccessToken()
  },

  async refresh(): Promise<AuthResponse> {
    const { useFetch } = useFetchConfig()

    const response = await useFetch('GET', 'auth/refresh')

    if (!response.ok) {
      throw new Error()
    }
    const data: AuthResponse = await response.json()
    setAccessToken(data.access_token)

    return data
  }
}
