import Cookies from 'js-cookie'

const TOKEN_COOKIE_NAME = 'access_token'

export function getAccessToken(): string | null {
  return Cookies.get(TOKEN_COOKIE_NAME) || null
}

export function setAccessToken(token: string): void {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + 7)

  Cookies.set(TOKEN_COOKIE_NAME, token)
  Cookies.set(`${TOKEN_COOKIE_NAME}_expiration`, expirationDate)
}

export function removeAccessToken(): void {
  Cookies.remove(TOKEN_COOKIE_NAME)
}

export function getTokenExpiration(): Date | null {
  return new Date(Cookies.get(`${TOKEN_COOKIE_NAME}_expiration`))
}
