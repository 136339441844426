import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    beforeEnter: auth
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: { hideNavigation: true }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/UsersView.vue'),
    beforeEnter: auth
  },
  {
    path: '/skills',
    name: 'skills',
    component: () => import('@/views/SkillsView.vue'),
    beforeEnter: auth
  },
  {
    path: '/personal-informations',
    name: 'personal-informations',
    component: () => import('@/views/PersonalInformationsView.vue'),
    beforeEnter: auth
  },
  {
    path: '/states',
    name: 'states',
    component: () => import('@/views/StatesView.vue'),
    beforeEnter: auth
  },
  {
    path: '/cities',
    name: 'cities',
    component: () => import('@/views/CityView.vue'),
    beforeEnter: auth
  },
  {
    path: '/employers',
    name: 'employers',
    component: () => import('@/views/EmployersView.vue'),
    beforeEnter: auth
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
