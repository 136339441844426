<template>
  <v-navigation-drawer permanent v-if="!$route.meta.hideNavigation">
    <v-list nav class="d-flex flex-column pa-10 h-100">
      <img src="@/assets/logo.svg" alt="logo" class="mb-10" />
      <router-link v-for="(link, index) in links" :key="index" :to="link.to" class="link" exact>
        <v-list-item :key="index" :prepend-icon="link.icon" :title="link.text"> </v-list-item>
      </router-link>

      <v-btn @click="logout" class="mt-auto bg-red-lighten-1">Logout</v-btn>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import AuthService from '@/services/AuthService'
import type { NavigationLink } from '@/interfaces'
import { useRouter } from 'vue-router'
const router = useRouter()

const links: NavigationLink[] = [
  {
    text: 'Users',
    icon: 'mdi-account',
    to: '/users'
  },
  {
    text: 'Jobs Seekers',
    icon: 'mdi-file-document-outline',
    to: '/personal-informations'
  },
  {
    text: 'States',
    icon: 'mdi-earth',
    to: '/states'
  },
  {
    text: 'Cities',
    icon: 'mdi-city',
    to: '/cities'
  },
  {
    text: 'Skills',
    icon: 'mdi-code-brackets',
    to: '/skills'
  },
  {
    text: 'Employers',
    icon: 'mdi-briefcase-account',
    to: '/employers'
  }
]

async function logout() {
  await AuthService.logout()
  router.push('/login')
}
</script>

<style lang="scss">
$background: rgba(1, 88, 228, 0.2);

.link {
  text-decoration: none;
  color: inherit;
  border-radius: 15px;

  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: none;
    background-color: $background;
  }
}

.router-link-exact-active {
  background-color: $background;
}
</style>
