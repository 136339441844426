import { ref } from 'vue'
import { getAccessToken } from '@/services/CookieService'

export function useFetchConfig() {
  const baseUrl = import.meta.env.VITE_APP_BASE_URL
  const headers = ref({
    Accept: 'application/json',
    'Content-Type': 'application/json'
  })

  async function useFetch(method: string, url: string, data?: any) {
    const accessToken = getAccessToken()

    const options: RequestInit = {
      method,
      headers: {
        ...headers.value,
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
    }

    if (data) {
      options.body = JSON.stringify(data)
    }

    const response = await fetch(`${baseUrl}/api/${url}`, options)

    return response
  }

  return {
    baseUrl,
    headers,
    useFetch
  }
}
